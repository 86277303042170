import React, { Component } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Portfolio from "../components/portfolio"
import PropTypes from 'prop-types';


class PortfolioPage extends Component {

	componentDidMount() {
	}

	render() {
        const home = this.props.data.allContentfulAccueil.edges[0].node;
        const galleries = {};

        this.props.data.allContentfulGalerie.edges.forEach(edge => {
            if (!galleries[edge.node.topic.id]) galleries[edge.node.topic.id]=[];
            galleries[edge.node.topic.id].push(edge.node);
        })

		return(
		<Layout>
		<SEO title={home.portfolioTitle} description={home.portfolioSubTitle} />
	
        <section className="module-md" >
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="m-title c-align">
                        <h1>{home.portfolioTitle}</h1>
                        <h6>{home.portfolioSubTitle}</h6>
                    </div>
                </div>
            </div>
        </div>
        </section>

        

        {
            home.galeryTopics.map(topic=> 
                (
                    <section className="module" key={topic.id}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="m-title c-align">
                                    <h2>{topic.title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <Portfolio galleries={galleries[topic.id]} />
                    </section>
                )
            )
        }


	  </Layout>)
	}
}




PortfolioPage.propTypes = {
	data: PropTypes.object.isRequired,
}

export default PortfolioPage

export const staticQuery = graphql`
  query {
    allContentfulAccueil (limit:1) {
      edges {
        node {
          id
		
		  profile {
			json
		  }
		  portfolioTitle
		  portfolioSubTitle
          galeryTopics {
            id
            title
            url
		  }
        }
      }
    }
    allContentfulGalerie(limit:100, sort: {fields: [sortValue,title], order: ASC}) {
        edges {
          node {
            id
            url
            title
            imageSquare {
                fluid(resizingBehavior: FILL, maxWidth: 920, maxHeight: 920) {
                  src
                  srcSet
                  sizes
                }
            }
            topic {
                id
                title
                url
            }

          }
        }
      }      

  }
`